@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.about {
  background-color: #ffffff;
  padding: 48px 0 40px;
  h2 {
    font-weight: 300;
    font-size: 36px;
    line-height: 52px;
    margin-top: 0;
    margin-bottom: 32px;

    @include bp(mobile) {
      font-size: 72px;
      margin: 0 0 54px 0;
    }
    @include bp(tablet) {
      font-size: 42px;
      margin: 0 0 24px 0;
    }

    @include bp(desktop-large) {
      font-size: 48px;
      line-height: 56px;
      margin-bottom: 32px;
    }
    @include bp(desktop-wide) {
      font-size: 56px;
      line-height: 56px;
    }
  }
  @include bp(mobile) {
    padding: 104px 0 94px;
  }
  @include bp(tablet) {
    padding: 64px 0 32px;
  }
  @include bp(desktop-large) {
    padding: 74px 0 68px;
  }
  @include bp(desktop-wide) {
    padding: 96px 0 104px;
  }
}

.about__head {
  margin-bottom: 16px;
  p {
    font-weight: 300;
    font-size: 20px;
    color: $color__blue;
    margin: 0;
    @include bp(mobile) {
      font-size: 40px;
      line-height: 52px;
    }
    @include bp(tablet) {
      font-size: 24px;
      line-height: 32px;
    }
    @include bp(desktop-large) {
      font-size: 28px;
      line-height: 34px;
      max-width: 464px;
    }
    @include bp(desktop-wide) {
      font-size: 32px;
      line-height: 40px;
      max-width: 624px;
    }
  }
  @include bp(mobile) {
    margin-bottom: 28px;
  }
  @include bp(tablet) {
    margin-bottom: 7px;
  }
}

.about__desc {
  color: $color__black--lighter;
  p {
    margin-top: 0;
    @include bp(mobile) {
      font-size: 32px;
      line-height: 44px;
    }
    @include bp(tablet) {
      font-size: 20px;
      line-height: 28px;
    }
    @include bp(desktop-large) {
      font-size: 22px;
      line-height: 30px;
    }
    @include bp(desktop-wide) {
      font-size: 24px;
      line-height: 32px;
    }
  }
}

.about__container {
  padding-top: 0;
  padding-bottom: 0;
}
